<template>
<div class="flex flex-col space-y-8 p-3 pt-6 sm:p-6">
    <Khead />
    
    <div class="w-full">
        <div class="sm:mb-32 mb-64 bg-white p-5 sm:p-6 sm:w-frame rounded mx-auto ">
            <div v-if="!paymentStep && !findReloadStep">
                <div class="mb-4 md:mb-7 sm:text-2xl text-lg font-semibold flex space-x-4 items-center">
                    <span><svgicon name="compt2" class="h-10 sm:h-16 w-10 sm:w-16"/></span>
                    <span class=" leading-6" style="color:#222F5A">{{ $t("counterRecharge.title") }}</span>
                </div>
                <div class="mx-auto mb-4 sm:mb-6 bg-gray-300 w-11/12 p-2 rounded-2xl">
                    <div class="flex text-xs sm:text-sm relative">
                        <div @click="switchProcess" class="flex justify-center text-tex-3 font-bold py-2 lg:py-3 px-4 w-1/2 z-10 cursor-pointer" v-for="(process, i) in processes" :key="i">
                            <p :class="currentProcess == i ? ' text-tex-1': ''" >{{process}}</p>
                        </div>
                        <div :style="`transform: translateX(${ translatePercent }%)`" class="transition duration-500 ease-in-out w-1/2 absolute bg-white h-full" style="border-radius: 0.75rem;"></div>
                    </div>
                </div>
            </div>
            
            <div>
                <div class="" v-if=" billProcess && !pleaseWaitStep && !findReloadStep">
                    <KRechargeBillEntry v-if="!paymentStep"/>
                    <KRechargeBillPayment v-if="paymentStep"/>
                </div>

                <div class="" v-if=" !billProcess && !pleaseWaitStep && !findReloadStep">
                    <KMeterNumberEntry v-if="!paymentStep"/>
                    <KMeterPayment v-if="paymentStep"/>
                </div>
            </div>

            <KPleaseWait v-if="pleaseWaitStep"/>
            <ManageFindReload v-if="findReloadStep" />
        </div>
    </div>
</div>
</template>

<script>
//import Iconcompt2 from '../../assets/svg/compt2.svg?inline';
import KMeterNumberEntry from '../../components/purchasesComponents/KMeterNumberEntry'
import KMeterPayment from '../../components/purchasesComponents/KMeterPayment'
import KPleaseWait from '../../components/purchasesComponents/KPleaseWait'
import Khead from '@/components/partials/Khead'
import ManageFindReload from '../../components/findReloadComponents/ManageFindReload.vue';
import KRechargeBillEntry from '../../components/purchasesComponents/BillRecharge/KRechargeBillEntry'
import KRechargeBillPayment from '../../components/purchasesComponents/BillRecharge/KRechargeBillPayment'
import {billRechargeApi} from '@/main'

export default {

    data() {
        return {
            lang:'',
            ref:'',
            billProcessLocal: true,
            signMenu: false,
            processes: [this.$t("counterRecharge.process1"),this.$t("counterRecharge.process2")],
            currentProcess: 0,
        }
    },
    
    components:{
        //Iconcompt2,
        KMeterNumberEntry,
        KMeterPayment,
        KPleaseWait,
        Khead,
        ManageFindReload,
        KRechargeBillEntry,
        KRechargeBillPayment
    },
    
    computed: {
        billProcess(){
            return this.$store.getters.getBillProcessBoolean;
        },
 
        paymentStep(){
            return this.$store.getters.getPaymentStep;
        },
        pleaseWaitStep(){
            return this.$store.getters.getPleaseWaitStep;
        },

        findReloadStep() {
            return this.$store.getters.getFindReloadStep;
        },
        translatePercent(){
            return this.currentProcess * 100
        },
    },
    methods: {
        switchProcess(){
            this.currentProcess = this.currentProcess == 0 ? 1 : 0
            this.billProcessLocal = !this.billProcessLocal
            this.$store.commit('mutBillProcessBoolean', this.billProcessLocal)
        },
    },
    mounted() {

    this.lang= this.lang.substr(0,2)
     if (this.lang != 'fr') {
       this.lang = 'en'
       
     }
    this.ref=this.$route.query.ref;
    if (this.ref){
        this.$store.commit('mutBillProcessBoolean', true)
        this.$store.commit('mutPleaseWaitStep', false)
        this.$store.commit('mutFindReloadStep', false) 
         this.$store.commit('mutPaymentStep', true)
    }
    billRechargeApi
            .get(`/subscribers?reference=${this.ref}&local=${this.lang}`)
            .then(async (res) => {
              const data = res.data;
              console.log('data: ', data);
              if (data.status) {
                data.status == 422 ? this.invalidMeter = true : ''
              } else {
                this.$store.commit("mutBillProcessFirstPur", data);
                await this.$store.commit('mutBillProcessBoolean', true)
                await this.$store.commit("mutPaymentStep", true);
                console.log(this.$route.path);
                //redirect to /recharges ?
                //if (this.$route.path === "/") this.$router.push(`/recharges?ref=${this.subscriberNumber}&local=${this.lang}`);
              }
              
            })
            .catch((err) => {
              if (err.response) {
                const data = err.response.data;
                console.log('errdata: ', data);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        /* this.$store.commit('mutPaymentStep', false)
        this.$store.commit('mutPleaseWaitStep', false)
        this.$store.commit('mutFindReloadStep', false) */
        this.billProcess ? this.currentProcess = 0 : this.currentProcess = 1
        this.billProcessLocal = this.billProcess
    },
}
</script>

<style scoped>

</style>