<template>
    <div>
        <Iconcompt class="hidden sm:block mx-auto mb-1" />
        <IconcomptMobile class="mb-3 sm:hidden mx-auto" />
        <div>
            <div class="mb-4" v-if="hasNotEnougthMoney">
              <div class="bg-tex-16 rounded p-2">
                <span class="text-sm text-compteur-50">{{$t("counterRecharge.notEnougthMoney")}} </span>
              </div>
            </div>   
            <form @submit.prevent="passCountPay">
                <div>
                    <label class="text-sm" for="compId">{{ $t("counterRecharge.labelMeter") }}</label>
                    <br>
                    <input  placeholder="Ex: 24563368425"
                        v-model="counterNum"
                        class="p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus:border-kinput-2 rounded w-full leading-tight focus:outline-none focus:bg-white" 
                        id="compId" 
                        name="compId"
                        @input="resetValidation"
                        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        type="number"
                        min="0"
                        maxlength="20"
                        v-validate="'required|numeric'"
                        :class="{ ' border-tex-2': submitted && errors.has('compId') }">

                    <p v-show="submitted && errors.has('compId')" class="text-tex-2">{{ $t("counterRecharge.validate") }}</p>
                    <p v-show="invalidMeter" class="text-tex-2">{{ $t("counterRecharge.invalidMeter") }}</p>
                    <p v-show="blockedMeter" class="text-tex-2 text-sm">{{ $t("counterRecharge.blockedMeter") }}</p>
                </div>
                <br>
                <base-button color="secondary" :applyDisabledColor="!loading" full type="submit" :text="$t('counterRecharge.btn')" :loading="loading" :disabled=" !counterNum" />
            </form>
        </div>
        <div class="mt-6">
            <div class="found-reload p-2 sm:p-4 rounded-lg bg-kblue-100 border-tex-18">
                <div class="flex mb-2 sm:items-center">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99996 1.66663C5.39996 1.66663 1.66663 5.39996 1.66663 9.99996C1.66663 14.6 5.39996 18.3333 9.99996 18.3333C14.6 18.3333 18.3333 14.6 18.3333 9.99996C18.3333 5.39996 14.6 1.66663 9.99996 1.66663ZM10.0001 14.1667C9.54175 14.1667 9.16675 13.7917 9.16675 13.3334V10.0001C9.16675 9.54175 9.54175 9.16675 10.0001 9.16675C10.4584 9.16675 10.8334 9.54175 10.8334 10.0001V13.3334C10.8334 13.7917 10.4584 14.1667 10.0001 14.1667ZM9.16675 7.50004H10.8334V5.83337H9.16675V7.50004Z" fill="#286EDD"/>
                    </svg>
                    <div class="ml-2 text-xs md:text-base">
                        {{ $t("findreload.description") }}
                    </div>
                </div>
                <base-button :btnClass="'text-xs md:text-base'" full color="primary" @click="showFindReload" :text="$t('findreload.btn')" />
            </div>
        </div>
    </div>
</template>

<script>
// import LoaderCss from '@/components/LoaderCss'
import Iconcompt from '../../assets/svg/compteur1.svg?inline';
import IconcomptMobile from '../../assets/svg/compteur1mobile.svg?inline';
import axios from 'axios'
import BaseButton from '../BaseButton.vue';
export default {
    components: {
        Iconcompt,
        // LoaderCss,
        IconcomptMobile,
        BaseButton
    },


    data(){
        return {
            loading: false,
            submitted: false,
            invalidMeter: false,
            blockedMeter: false,
            hasNotEnougthMoney: false,
            counterNum: ''
        }
    },


    methods: {
        passCountPay(){
            this.invalidMeter = false
            this.blockedMeter = false
            this.submitted = true;
            this.$validator.validate().then(valid => {
            if (valid) {
                this.loading = true
                axios.post('/transaction', {
                    meterNumb: this.counterNum,
                    amount: '1',
                    userId: this.$store.getters.getAuthUser.id
                })
                .then(async res=>{
                    console.log("Where Am I ???");
                    const data = res.data
                    const debt = parseFloat(data.arrearAMT) + parseFloat(data.feeAMT)
                    const meter = {
                        number: data.meterNum,
                        user: data.customerName,
                        debt: debt,
                        transacId: data.transID,
                    }
                    this.$store.commit('mutCounterFirstPur', meter)
                    await this.$store.commit('mutPaymentStep', true)
                    //redirect to /recharges ?
                    if(this.$route.path==="/") this.$router.push('recharges')
                     //
                    if (this.hasNotEnougthMoney) {
                        this.hasNotEnougthMoney = false
                    }
                })
                .catch(err=>{
                    
                    console.log("I am were ???");
                    // const message = err.response.data.message;
                    if (err.response) {
                        // console.log('error response --->', err.response);
                      const data = err.response.data
                    //   console.log('data: ', data);
                      if (data.state == '-10006') {
                        // console.log('Invalid ====>');
                        this.invalidMeter = true
                      } 
                      else if (data.locked == 'meter blocked') {
                        // console.log('blockedMeter ====>');
                        this.blockedMeter = true
                      }
                      else if (data.state == '-10023') {
                          this.hasNotEnougthMoney = true
                      }
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            }
            });   
            
        },
        resetValidation() {
            if (this.invalidMeter) {
                this.invalidMeter = false
            }
            if (this.blockedMeter) {
                this.blockedMeter = false
            }
        },

        showFindReload() {
            this.$store.commit('mutFindReloadStep', true)
        }
    },
    mounted() {
        this.loading = false
        this.counterNum = this.$store.getters.getPrevCounterNumber
    },
}
</script>

<style scoped>

</style>
