<template>
  <div>
    <img src="@/assets/svg/electricBill.svg" class="h-56 w-64 sm:w-auto mx-auto" alt="">
    <div>
      <form @submit.prevent="passCountPay">
        <div>
          <label class="text-sm" for="compId">{{
            $t("counterRecharge.labelProcessBill")
          }}</label>
          <br />
          <input
            placeholder="Ex: 67983409590"
            v-model="subscriberNumber"
            class="
              p-3
              h-minput
              border-1.5 border-kborder-500
              hover:border-kinput-3
              focus:border-kinput-2
              rounded
              w-full
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="compId"
            name="compId"
            @input="resetValidation"
            maxlength="20"
            v-validate="'required'"
            :class="{ ' border-tex-2': submitted && errors.has('compId') }"
          />

          <p v-show="submitted && errors.has('compId')" class="text-tex-2">
            {{ $t("counterRecharge.validateSubscribeNumb") }}
          </p>
          <p v-show="invalidMeter" class="text-tex-2">
            {{ $t("counterRecharge.invalidSubscribeNumb") }}
          </p>
        </div>
        <br />
        <base-button
          color="secondary"
          :applyDisabledColor="!loading"
          full
          type="submit"
          :text="$t('counterRecharge.btn')"
          :loading="loading"
          :disabled="!subscriberNumber"
        />
      </form>
    </div>
  </div>
</template>

<script>
import {billRechargeApi} from '@/main'
import BaseButton from "../../BaseButton.vue";
export default {
  components: {
    BaseButton,
  },

  data() {
    return {
      loading: false,
      submitted: false,
      invalidMeter: false,
      subscriberNumber: "",
    };
  },

  methods: {
    passCountPay() {
      this.invalidMeter = false;
      this.submitted = true;
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.loading = true;
          billRechargeApi
            .get(`/subscribers?reference=${this.subscriberNumber}`)
            .then(async (res) => {
              const data = res.data;
              console.log('data: ', data);
              if (data.status) {
                data.status == 422 ? this.invalidMeter = true : ''
              } else {
                this.$store.commit("mutBillProcessFirstPur", data);
                await this.$store.commit("mutPaymentStep", true);
                //redirect to /recharges ?
                if (this.$route.path === "/") this.$router.push("recharges");
              }
              
            })
            .catch((err) => {
              if (err.response) {
                const data = err.response.data;
                console.log('errdata: ', data);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    resetValidation() {
      if (this.invalidMeter) {
          this.invalidMeter = false
      }
    },
  },
  mounted() {
    this.loading = false;
    this.subscriberNumber = this.$store.getters.getPrevCounterNumber;
  },
};
</script>

<style scoped>
</style>
