<template>
    <div>
        <div class="mt-8 flex flex-col items-center justify-center">
            <span class="sm:text-2xl text-base sm:font-semibold" style="color:#222F5A">{{ $t("pleaseWait.title") }}</span>
            <span class="text-center">{{ $t("pleaseWait.subtitle") }}</span>
        </div>
        <div class="my-16 mx-auto" style="height: 300px; width:300px">
            <img src="@/assets/gifts/animation_500_kfza30pe.gif" alt="">
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>